import {
    FETCH_ADMIN_CHAPTER_EDIT_REQUEST,
    FETCH_ADMIN_CHAPTER_EDIT_FAILURE,
    FETCH_ADMIN_CHAPTER_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchAdminChapterEditRequest,
    FetchAdminChapterEditSuccess,
    FetchAdminChapterEditSuccessPayload,
    FetchAdminChapterEditFailure,
    FetchAdminChapterEditFailurePayload,
  } from "./types";
  
  export const fetchAdminChapterEditRequest = (
    query: any
  ): FetchAdminChapterEditRequest => ({
    type: FETCH_ADMIN_CHAPTER_EDIT_REQUEST,
    query,
  });
  
  export const fetchAdminChapterEditSuccess = (
    payload: FetchAdminChapterEditSuccessPayload
  ): FetchAdminChapterEditSuccess => ({
    type: FETCH_ADMIN_CHAPTER_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchAdminChapterEditFailure = (
    payload: FetchAdminChapterEditFailurePayload
  ): FetchAdminChapterEditFailure => ({
    type: FETCH_ADMIN_CHAPTER_EDIT_FAILURE,
    payload,
  });