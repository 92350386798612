import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchAdminUserListFailure, fetchAdminUserListSuccess } from "./actions";
import { FETCH_ADMIN_USER_LIST_REQUEST } from "./actionTypes";


import  { getAdminUserList }  from "./api";


  

/*
  Worker Saga: Fired on FETCH_ADMIN_USER_LIST_REQUEST action
*/
export function* fetchAdminUserListSaga() : any { 
  try {

    const response = yield call(getAdminUserList);

    yield put(
      fetchAdminUserListSuccess({
        result: response,
      })
    );
  



  } catch (e) {
    let errorMessage = "Failed to do something exceptional";
    if (e instanceof Error) {
      errorMessage = e.message;
    }
    yield put( fetchAdminUserListFailure({ error: errorMessage }));

  }
}

/*
  Starts worker saga on latest dispatched `FETCH_ADMIN_USER_LIST_REQUEST` action.
  Allows concurrent increments.
*/
function* AdminUserListSaga() {
  yield all([takeLatest(FETCH_ADMIN_USER_LIST_REQUEST, fetchAdminUserListSaga)]);
}

export default AdminUserListSaga;
