import {
  FETCH_ADMIN_USER_LIST_REQUEST,
  FETCH_ADMIN_USER_LIST_SUCCESS,
  FETCH_ADMIN_USER_LIST_FAILURE,
  FETCH_ADMIN_USER_LIST_INITIAL,
} from "./actionTypes";

import { AdminUserListActions, AdminUserListState } from "./types";

const initialState: AdminUserListState = {
  loading: false,
  result: null,
  error: null,
};

export default (state = initialState, action: AdminUserListActions) => {
  switch (action.type) {
    case FETCH_ADMIN_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADMIN_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        error: null,
      };
    case FETCH_ADMIN_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload.error,
      };
    case FETCH_ADMIN_USER_LIST_INITIAL:
      return {
        ...state,
        loading: false,
        result: null,
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};