import {
    FETCH_ADMIN_EMOJI_EDIT_REQUEST,
    FETCH_ADMIN_EMOJI_EDIT_FAILURE,
    FETCH_ADMIN_EMOJI_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchAdminEmojiEditRequest,
    FetchAdminEmojiEditSuccess,
    FetchAdminEmojiEditSuccessPayload,
    FetchAdminEmojiEditFailure,
    FetchAdminEmojiEditFailurePayload,
  } from "./types";
  
  export const fetchAdminEmojiEditRequest = (
    query: any
  ): FetchAdminEmojiEditRequest => ({
    type: FETCH_ADMIN_EMOJI_EDIT_REQUEST,
    query,
  });
  
  export const fetchAdminEmojiEditSuccess = (
    payload: FetchAdminEmojiEditSuccessPayload
  ): FetchAdminEmojiEditSuccess => ({
    type: FETCH_ADMIN_EMOJI_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchAdminEmojiEditFailure = (
    payload: FetchAdminEmojiEditFailurePayload
  ): FetchAdminEmojiEditFailure => ({
    type: FETCH_ADMIN_EMOJI_EDIT_FAILURE,
    payload,
  });