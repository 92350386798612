import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchAdminChapterEditFailure, fetchAdminChapterEditSuccess } from "./actions";
import { FETCH_ADMIN_CHAPTER_EDIT_REQUEST } from "./actionTypes";

import { FetchAdminChapterEditRequest } from "./types";

import { getAdminChapterEditInsert, getAdminChapterEditUpdate, getAdminChapterEditElim } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchSerieChapterSaga } from "../serieChapter/sagas";

export function* fetchAdminChapterEditSaga(action: FetchAdminChapterEditRequest): any {
  try {
    let response: any;

    
  
    if (action.query.idDelete && action.query.idDelete!='') {
      response = yield call(getAdminChapterEditElim, action.query.idDelete);
    } else {
      if (action.query.id && action.query.id!='') {
        response = yield call(getAdminChapterEditUpdate, action.query.id, action.query);
      } else {
        response = yield call(getAdminChapterEditInsert, action.query);  
      }
    }

    if (response && response.id && response.id != "") {
      yield call(fetchSerieChapterSaga, {admin: true});
      yield put(
        fetchAdminChapterEditSuccess({
          result: response,
        })
      );
    }


  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    yield put(fetchAdminChapterEditFailure({ error: errorMessage }));
  }
}

function* AdminChapterEditSaga() {
  yield all([takeLatest(FETCH_ADMIN_CHAPTER_EDIT_REQUEST, fetchAdminChapterEditSaga)]);
}

export default AdminChapterEditSaga;
