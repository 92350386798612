import db from "../_root/apiBridge";

export async function getAdminCommentEditUpdate(query: any): Promise<any> {
  return new Promise((resolve, reject) => {
    db.collection("comments")
      .doc(query.idDelete)
      .update({ delete: !query.deleteAction })
      .then(function () {
        resolve({ id: query.idDelete });
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export async function getAdminCommentEdit(
  id: string,
  query: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    db.collection("comments")
      .doc(id)
      .update(query)
      .then(() => {
        resolve({
          id,
        });
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
