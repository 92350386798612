import {
    FETCH_EMOJI_REQUEST,
    FETCH_EMOJI_SUCCESS,
    FETCH_EMOJI_FAILURE,
  } from "./actionTypes";
  
  import { EmojiActions, EmojiState } from "./types";
  
  const initialState: EmojiState = {
    loading: false,
    result: null,
    error: null,
  };
  
  export default (state = initialState, action: EmojiActions) => {
    switch (action.type) {
      case FETCH_EMOJI_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_EMOJI_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload.result,
          error: null,
        };
      case FETCH_EMOJI_FAILURE:
        return {
          ...state,
          loading: false,
          result: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };