import {
    FETCH_ADMIN_USER_LIST_REQUEST,
    FETCH_ADMIN_USER_LIST_FAILURE,
    FETCH_ADMIN_USER_LIST_SUCCESS,
    FETCH_ADMIN_USER_LIST_INITIAL,
  } from "./actionTypes";
  import {
    FetchAdminUserListRequest,
    FetchAdminUserListSuccess,
    FetchAdminUserListSuccessPayload,
    FetchAdminUserListFailure,
    FetchAdminUserListFailurePayload,
    FetchAdminUserListInitial,
  } from "./types";
  
  export const fetchAdminUserListRequest = (): FetchAdminUserListRequest => ({
    type: FETCH_ADMIN_USER_LIST_REQUEST,
  });
  
  export const fetchAdminUserListSuccess = (
    payload: FetchAdminUserListSuccessPayload
  ): FetchAdminUserListSuccess => ({
    type: FETCH_ADMIN_USER_LIST_SUCCESS,
    payload,
  });
  
  export const fetchAdminUserListFailure = (
    payload: FetchAdminUserListFailurePayload
  ): FetchAdminUserListFailure => ({
    type: FETCH_ADMIN_USER_LIST_FAILURE,
    payload,
  });

  export const fetchAdminUserListInitial = (): FetchAdminUserListInitial => ({
    type: FETCH_ADMIN_USER_LIST_INITIAL,
  });