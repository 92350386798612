import {
  FETCH_USER_LOGIN_REQUEST,
  FETCH_USER_LOGIN_SUCCESS,
  FETCH_USER_LOGIN_FAILURE,
  FETCH_USER_LOGOUT,
} from "./actionTypes";

import { UserLoginActions, UserLoginState } from "./types";

const initialState: UserLoginState = {
  loading: false,
  result: null,
  error: null,
};

export default (state = initialState, action: UserLoginActions) => {
  switch (action.type) {
    case FETCH_USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        error: null,
      };
    case FETCH_USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        result: null,
        error: action.payload.error,
      };
    case FETCH_USER_LOGOUT:
      return {
        ...state,
        loading: false,
        result: null,
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};