import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchAdminUserNewFailure, fetchAdminUserNewSuccess } from "./actions";
import { FETCH_ADMIN_USER_NEW_REQUEST } from "./actionTypes";

import { FetchAdminUserNewRequest } from "./types";

import { getAdminUserNewInsert } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchUserLoginSaga } from "../userLogin/sagas";

export function* fetchAdminUserNewSaga(action: FetchAdminUserNewRequest): any {
  try {
    let response: any;
    let user: any;

    user = yield select(getResultUserLoginSelector);

    //if (type == "insert") {
      response = yield call(getAdminUserNewInsert, user.uid, action.query);
    //}
    /*
    if (type == "update") {
      user = yield select(getResultUserLoginSelector);
      response = yield call(getAdminUserNewUpdate, user.uid, action.query);
    }
    if (type == "rs") {
      response = yield call(getAdminUserNewRs, action.query);
    }
    */

    if (response && response.uid && response.uid != "") {
      
      /*
      if (type == "insert" || type == "rs" ) {
        yield call(fetchUserLoginSaga, {
          query: { uid: response.uid, email: response.email },
        });
      }

      if (type == "update") {
        yield call(fetchUserLoginSaga, {
          query: { uid: response.uid, email: user.email },
        });
      }
      */

      yield put(
        fetchAdminUserNewSuccess({
          result: response,
        })
      );
    }
  } catch (error) {
    let errorMessage='';
    if ( error.toString().includes("email address is already in use")) {
      errorMessage = "Este correo electrónico está siendo usado en otra cuenta";
    } else {
      errorMessage = error;
    }
    yield put(fetchAdminUserNewFailure({ error: errorMessage }));
  }
}

function* AdminUserNewSaga() {
  yield all([takeLatest(FETCH_ADMIN_USER_NEW_REQUEST, fetchAdminUserNewSaga)]);
}

export default AdminUserNewSaga;
