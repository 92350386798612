import {
    FETCH_COIN_VALUE_REQUEST,
    FETCH_COIN_VALUE_SUCCESS,
    FETCH_COIN_VALUE_FAILURE,
  } from "./actionTypes";
  
  import { CoinValueActions, CoinValueState } from "./types";
  
  const initialState: CoinValueState = {
    loading: true,
    result: [],
    error: null,
  };
  
  export default (state = initialState, action: CoinValueActions) => {
    switch (action.type) {
      case FETCH_COIN_VALUE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_COIN_VALUE_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload.result,
          error: null,
        };
      case FETCH_COIN_VALUE_FAILURE:
        return {
          ...state,
          loading: false,
          result: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };