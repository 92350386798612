import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchAdminEmojiEditFailure, fetchAdminEmojiEditSuccess } from "./actions";
import { FETCH_ADMIN_EMOJI_EDIT_REQUEST } from "./actionTypes";

import { FetchAdminEmojiEditRequest } from "./types";

import { getAdminEmojiEditInsert, getAdminEmojiEditUpdate, getAdminEmojiEditElim } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchEmojiSaga } from "../emoji/sagas";

export function* fetchAdminEmojiEditSaga(action: FetchAdminEmojiEditRequest): any {
  try {
    let response: any;

    
  
    if (action.query.idDelete && action.query.idDelete!='') {
      response = yield call(getAdminEmojiEditElim, action.query.idDelete);
    } else {
      if (action.query.id && action.query.id!='') {
        response = yield call(getAdminEmojiEditUpdate, action.query.id, action.query);
      } else {
        response = yield call(getAdminEmojiEditInsert, action.query);  
      }
    }

    if (response && response.id && response.id != "") {
      //yield call(fetchEmojiSaga, { query: {serieId: action.query.serie_id} });
      yield call(fetchEmojiSaga, {
        query: { serieId: action.query.serie_id },
      });
      yield put(
        fetchAdminEmojiEditSuccess({
          result: response,
        })
      );
    }


  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    yield put(fetchAdminEmojiEditFailure({ error: errorMessage }));
  }
}

function* AdminEmojiEditSaga() {
  yield all([takeLatest(FETCH_ADMIN_EMOJI_EDIT_REQUEST, fetchAdminEmojiEditSaga)]);
}

export default AdminEmojiEditSaga;
