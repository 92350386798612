import {
    FETCH_USER_INFO_REQUEST,
    FETCH_USER_INFO_FAILURE,
    FETCH_USER_INFO_SUCCESS,
    FETCH_USER_INFO_INITIAL,
  } from "./actionTypes";
  import {
    FetchUserInfoRequest,
    FetchUserInfoSuccess,
    FetchUserInfoSuccessPayload,
    FetchUserInfoFailure,
    FetchUserInfoFailurePayload,
    FetchUserInfoInitial,
  } from "./types";
  
  export const fetchUserInfoRequest = (uid:string=''): FetchUserInfoRequest => ({
    type: FETCH_USER_INFO_REQUEST,
    uid,
  });
  
  export const fetchUserInfoSuccess = (
    payload: FetchUserInfoSuccessPayload
  ): FetchUserInfoSuccess => ({
    type: FETCH_USER_INFO_SUCCESS,
    payload,
  });
  
  export const fetchUserInfoFailure = (
    payload: FetchUserInfoFailurePayload
  ): FetchUserInfoFailure => ({
    type: FETCH_USER_INFO_FAILURE,
    payload,
  });

  export const fetchUserInfoInitial = (): FetchUserInfoInitial => ({
    type: FETCH_USER_INFO_INITIAL,
  });