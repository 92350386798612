import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchUserInfoEditFailure, fetchUserInfoEditSuccess } from "./actions";
import { FETCH_USER_INFO_EDIT_REQUEST } from "./actionTypes";

import { FetchUserInfoEditRequest } from "./types";

import { getUserInfoEdit, setLikeChapter } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";
import { getResultUserInfoSelector } from "../userInfo/selectors";

import { fetchUserInfoSaga } from "../userInfo/sagas";

export function* fetchUserInfoEditSaga(action: FetchUserInfoEditRequest): any {
  try {
    let response: any;
    let user: any;
    let uid: string;

    if (action.query.field.uid && action.query.field.uid != '') {
      uid = action.query.field.uid
    } else {
      user = yield select(getResultUserLoginSelector);
      uid = user.uid
    }




    response = yield call(getUserInfoEdit, uid, action.query);

    //Alert.alert(JSON.stringify(response));

    if (response && response.id) {

      let q = action.query;

      if (q.type == 'chapter') {
        if (q.field.like && q.field.like == true) {
          setLikeChapter(q.id, true);
        }
        if (q.field.like == false) {
          setLikeChapter(q.id, false);
        }
      }

      if (action.query.field.uid && action.query.field.uid != '') {
        yield call(fetchUserInfoSaga, { uid: action.query.field.uid })
      } else {
        yield call(fetchUserInfoSaga, { uid: '' })
      }

      yield put(
        fetchUserInfoEditSuccess({
          result: response,
        })
      );
    }
  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    yield put(fetchUserInfoEditFailure({ error: errorMessage }));
  }
}

function* UserInfoEditSaga() {
  yield all([takeLatest(FETCH_USER_INFO_EDIT_REQUEST, fetchUserInfoEditSaga)]);
}

export default UserInfoEditSaga;
