import  db  from '../_root/apiBridge';



export async function getAdminUserList(): Promise<any> {



  return new Promise((resolve, reject) => {


      db
        .collection('users')
        .orderBy('email', 'asc')
        .get()
        .then(doc => {


          if (doc.size == 0) {
            resolve([]);
          }

          let result: any = [];
          

          doc.forEach(docu => {  
            
            
            db
            .collection('users_rol')
            .doc(docu.id)
            .get()
            .then((doc2) => {

              if (doc2.exists) { 
                //alert('exist');
                let rol:string[]|null=[];
                if (doc2.data().admin==true) {
                  rol.push('admin');
                }
                if (doc2.data().editor==true) {
                  rol.push('editor');
                }
                result.push({ ...docu.data(), id: docu.id, rol });
              } else {
                result.push({ ...docu.data(), id: docu.id, rol:[] });
              }

              //console.log('result.length', result.length);

              if (result.length === doc.size) {
                resolve(result);
              }


            })

            

          });

        



        }).catch((error) => {
          console.log(error);
          reject(error);
        });




  








  });

}