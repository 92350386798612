import db from '../_root/apiBridge';
import { timestamp } from '../_root/apiBridge';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
//import auth from '@react-native-firebase/auth';




export async function getUserEditInsert(query:any): Promise<any> {


  const {
    email,
    name,
    phone,
  } = query;

  return new Promise((resolve, reject) => {
   

    try {

      createUserWithEmailAndPassword(getAuth(), query.email, query.password)
      //auth().createUserWithEmailAndPassword(query.email, query.password) // create
        .then((currentUser) => {

          db.collection('users').doc(currentUser.user.uid)
            .set({
              email: email,
              name: name,
              phone: phone??'',
              audit_created: timestamp.fromDate(new Date()),
              deviceToken: '',
            }).then(()=>{
              resolve({
                uid: currentUser.user.uid,
                email: email,        
              });
            });


        }).catch(error => {
          
          reject(error);

        });


    } catch (error) {
      reject(error);
    }


  });

}


export async function getUserEditUpdate(uid: string, query:any): Promise<any> {


  return new Promise((resolve, reject) => {

    db
    .collection('users')
    .doc(uid)
    .update({
      name: query.name,
      phone: query.phone??'',
      audit_updated: timestamp.fromDate(new Date()),
    }).then(() => {      
      resolve({
        uid: uid        
      });
    })
    .catch((error) => {
      reject(error);
    });

  });
}



export async function getUserEditRs(query:any): Promise<any> {


  const {
    uid,
    email,
    name,
    provider
  } = query;

  return new Promise((resolve, reject) => {
   

    try {

      db.collection('users')
        .doc(uid)
        .get()
        .then((documentSnapshot) => {
          if (documentSnapshot.exists) {
              
              resolve({
                uid,
                email        
              });

          } else {

            db.collection('users').doc(uid)
            .set({
              email,
              name,
              phone: '',
              provider,
              deviceToken: '',
              audit_created: timestamp.fromDate(new Date()),              
            }).then(()=>{
              resolve({
                uid,
                email          
              });
            });

          }
        })


    } catch (error) {
      reject(error);
    }


  });

}