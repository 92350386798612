import {
    FETCH_COMMENT_LIST_REQUEST,
    FETCH_COMMENT_LIST_SUCCESS,
    FETCH_COMMENT_LIST_FAILURE,
  } from "./actionTypes";
  
  import { CommentListActions, CommentListState } from "./types";
  
  const initialState: CommentListState = {
    loading: false,
    result: null,
    error: null,
  };
  
  export default (state = initialState, action: CommentListActions) => {
    switch (action.type) {
      case FETCH_COMMENT_LIST_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_COMMENT_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload.result,
          error: null,
        };
      case FETCH_COMMENT_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          result: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };