import {
    FETCH_EMOJI_REQUEST,
    FETCH_EMOJI_FAILURE,
    FETCH_EMOJI_SUCCESS,
  } from "./actionTypes";
  import {
    FetchEmojiRequest,
    FetchEmojiSuccess,
    FetchEmojiSuccessPayload,
    FetchEmojiFailure,
    FetchEmojiFailurePayload,
  } from "./types";
  
  export const fetchEmojiRequest = (
    query: any
  ): FetchEmojiRequest => ({
    type: FETCH_EMOJI_REQUEST,
    query,
  });
  
  export const fetchEmojiSuccess = (
    payload: FetchEmojiSuccessPayload
  ): FetchEmojiSuccess => ({
    type: FETCH_EMOJI_SUCCESS,
    payload,
  });
  
  export const fetchEmojiFailure = (
    payload: FetchEmojiFailurePayload
  ): FetchEmojiFailure => ({
    type: FETCH_EMOJI_FAILURE,
    payload,
  });