import {
    FETCH_USER_INFO_EDIT_REQUEST,
    FETCH_USER_INFO_EDIT_SUCCESS,
    FETCH_USER_INFO_EDIT_FAILURE,
  } from "./actionTypes";
  
  import { UserInfoEditActions, UserInfoEditState } from "./types";
  
  const initialState: UserInfoEditState = {
    loading: false,
    result: null,
    error: null,
  };
  
  export default (state = initialState, action: UserInfoEditActions) => {
    switch (action.type) {
      case FETCH_USER_INFO_EDIT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_USER_INFO_EDIT_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload.result,
          error: null,
        };
      case FETCH_USER_INFO_EDIT_FAILURE:
        return {
          ...state,
          loading: false,
          result: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };