import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchAdminSlideEditFailure, fetchAdminSlideEditSuccess } from "./actions";
import { FETCH_ADMIN_SLIDE_EDIT_REQUEST } from "./actionTypes";

import { FetchAdminSlideEditRequest } from "./types";

import { getAdminSlideEditInsert, getAdminSlideEditUpdate, getAdminSlideEditElim } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchSlideSaga } from "../slide/sagas";

export function* fetchAdminSlideEditSaga(action: FetchAdminSlideEditRequest): any {
  try {
    let response: any;

    
  
    if (action.query.idDelete && action.query.idDelete!='') {
      response = yield call(getAdminSlideEditElim, action.query.idDelete);
    } else {
      if (action.query.id && action.query.id!='') {
        response = yield call(getAdminSlideEditUpdate, action.query.id, action.query);
      } else {
        response = yield call(getAdminSlideEditInsert, action.query);  
      }
    }

    if (response && response.id && response.id != "") {
      yield call(fetchSlideSaga, {admin: true});
      yield put(
        fetchAdminSlideEditSuccess({
          result: response,
        })
      );
    }


  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    yield put(fetchAdminSlideEditFailure({ error: errorMessage }));
  }
}

function* AdminSlideEditSaga() {
  yield all([takeLatest(FETCH_ADMIN_SLIDE_EDIT_REQUEST, fetchAdminSlideEditSaga)]);
}

export default AdminSlideEditSaga;
