import { all, call, put, takeLatest, select } from "redux-saga/effects";

import {
  fetchAdminCommentEditFailure,
  fetchAdminCommentEditSuccess,
} from "./actions";
import { FETCH_ADMIN_COMMENT_EDIT_REQUEST } from "./actionTypes";

import { FetchAdminCommentEditRequest } from "./types";

import { getAdminCommentEditUpdate, getAdminCommentEdit } from "./api";

import { fetchCommentListSaga } from "../commentList/sagas";

export function* fetchAdminCommentEditSaga(
  action: FetchAdminCommentEditRequest
): any {
  try {
    let response: any;

    //response = yield call(getAdminCommentEditUpdate, action.query );

    if (action.query.idDelete && action.query.idDelete != "") {
      response = yield call(getAdminCommentEditUpdate, action.query);
    } else {
      response = yield call(getAdminCommentEdit, action.query.id, action.query);
    }

    if (response && response.id && response.id != "") {
      yield call(fetchCommentListSaga);
      yield put(
        fetchAdminCommentEditSuccess({
          result: response,
        })
      );
    }
  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    yield put(fetchAdminCommentEditFailure({ error: errorMessage }));
  }
}

function* AdminCommentEditSaga() {
  yield all([
    takeLatest(FETCH_ADMIN_COMMENT_EDIT_REQUEST, fetchAdminCommentEditSaga),
  ]);
}

export default AdminCommentEditSaga;
