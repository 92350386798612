import {
    FETCH_ADMIN_SLIDE_EDIT_REQUEST,
    FETCH_ADMIN_SLIDE_EDIT_FAILURE,
    FETCH_ADMIN_SLIDE_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchAdminSlideEditRequest,
    FetchAdminSlideEditSuccess,
    FetchAdminSlideEditSuccessPayload,
    FetchAdminSlideEditFailure,
    FetchAdminSlideEditFailurePayload,
  } from "./types";
  
  export const fetchAdminSlideEditRequest = (
    query: any
  ): FetchAdminSlideEditRequest => ({
    type: FETCH_ADMIN_SLIDE_EDIT_REQUEST,
    query,
  });
  
  export const fetchAdminSlideEditSuccess = (
    payload: FetchAdminSlideEditSuccessPayload
  ): FetchAdminSlideEditSuccess => ({
    type: FETCH_ADMIN_SLIDE_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchAdminSlideEditFailure = (
    payload: FetchAdminSlideEditFailurePayload
  ): FetchAdminSlideEditFailure => ({
    type: FETCH_ADMIN_SLIDE_EDIT_FAILURE,
    payload,
  });