
import db from '../_root/apiBridge';  




export async function getCoinValue(): Promise<any> {

  return new Promise((resolve, reject) => {

    

    db
      .collection('coins_value')
      .orderBy('coin', 'asc')
      .get()
      .then(doc => {


        if (doc.size == 0) {
          resolve([]);
        }

        let result: any = [];
        doc.forEach(docu => {            
          result.push({ ...docu.data(), id: docu.id });
        });

        if (result.length === doc.size) {
          console.log('', result)
          resolve(result);
        }



      }).catch((error) => {
        console.log(error);
        reject(error);
      });




});

}
