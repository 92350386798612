import {
    FETCH_ADMIN_SERIE_EDIT_REQUEST,
    FETCH_ADMIN_SERIE_EDIT_FAILURE,
    FETCH_ADMIN_SERIE_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchAdminSerieEditRequest,
    FetchAdminSerieEditSuccess,
    FetchAdminSerieEditSuccessPayload,
    FetchAdminSerieEditFailure,
    FetchAdminSerieEditFailurePayload,
  } from "./types";
  
  export const fetchAdminSerieEditRequest = (
    query: any
  ): FetchAdminSerieEditRequest => ({
    type: FETCH_ADMIN_SERIE_EDIT_REQUEST,
    query,
  });
  
  export const fetchAdminSerieEditSuccess = (
    payload: FetchAdminSerieEditSuccessPayload
  ): FetchAdminSerieEditSuccess => ({
    type: FETCH_ADMIN_SERIE_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchAdminSerieEditFailure = (
    payload: FetchAdminSerieEditFailurePayload
  ): FetchAdminSerieEditFailure => ({
    type: FETCH_ADMIN_SERIE_EDIT_FAILURE,
    payload,
  });