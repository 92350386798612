import {
    FETCH_SERIE_CHAPTER_REQUEST,
    FETCH_SERIE_CHAPTER_SUCCESS,
    FETCH_SERIE_CHAPTER_FAILURE,
  } from "./actionTypes";
  
  import { SerieChapterActions, SerieChapterState } from "./types";
  
  const initialState: SerieChapterState = {
    loading: false,
    result: [],
    error: null,
  };
  
  export default (state = initialState, action: SerieChapterActions) => {
    switch (action.type) {
      case FETCH_SERIE_CHAPTER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_SERIE_CHAPTER_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload.result,
          error: null,
        };
      case FETCH_SERIE_CHAPTER_FAILURE:
        return {
          ...state,
          loading: false,
          result: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };