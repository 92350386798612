import {
    FETCH_USER_INFO_EDIT_REQUEST,
    FETCH_USER_INFO_EDIT_FAILURE,
    FETCH_USER_INFO_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchUserInfoEditRequest,
    FetchUserInfoEditSuccess,
    FetchUserInfoEditSuccessPayload,
    FetchUserInfoEditFailure,
    FetchUserInfoEditFailurePayload,
    Query
  } from "./types";
  
  export const fetchUserInfoEditRequest = (
    query: Query
  ): FetchUserInfoEditRequest => ({
    type: FETCH_USER_INFO_EDIT_REQUEST,
    query,
  });
  
  export const fetchUserInfoEditSuccess = (
    payload: FetchUserInfoEditSuccessPayload
  ): FetchUserInfoEditSuccess => ({
    type: FETCH_USER_INFO_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchUserInfoEditFailure = (
    payload: FetchUserInfoEditFailurePayload
  ): FetchUserInfoEditFailure => ({
    type: FETCH_USER_INFO_EDIT_FAILURE,
    payload,
  });