import {
    FETCH_ADMIN_COIN_VALUE_EDIT_REQUEST,
    FETCH_ADMIN_COIN_VALUE_EDIT_FAILURE,
    FETCH_ADMIN_COIN_VALUE_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchAdminCoinValueEditRequest,
    FetchAdminCoinValueEditSuccess,
    FetchAdminCoinValueEditSuccessPayload,
    FetchAdminCoinValueEditFailure,
    FetchAdminCoinValueEditFailurePayload,
  } from "./types";
  
  export const fetchAdminCoinValueEditRequest = (
    query: any
  ): FetchAdminCoinValueEditRequest => ({
    type: FETCH_ADMIN_COIN_VALUE_EDIT_REQUEST,
    query,
  });
  
  export const fetchAdminCoinValueEditSuccess = (
    payload: FetchAdminCoinValueEditSuccessPayload
  ): FetchAdminCoinValueEditSuccess => ({
    type: FETCH_ADMIN_COIN_VALUE_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchAdminCoinValueEditFailure = (
    payload: FetchAdminCoinValueEditFailurePayload
  ): FetchAdminCoinValueEditFailure => ({
    type: FETCH_ADMIN_COIN_VALUE_EDIT_FAILURE,
    payload,
  });