import { all, fork } from "redux-saga/effects";

import serieChapterSaga from "../serieChapter/sagas";
import userLoginSaga from "../userLogin/sagas";
import userEditSaga from "../userEdit/sagas";
import userInfoSaga from "../userInfo/sagas";
import userInfoEditSaga from "../userInfoEdit/sagas";
import slideSaga from "../slide/sagas";

import adminUserListSaga from "../adminUserList/sagas";
import adminUserEditSaga from "../adminUserEdit/sagas";
import adminUserNewSaga from "../adminUserNew/sagas";
import adminSerieEditSaga from "../adminSerieEdit/sagas";
import adminChapterEditSaga from "../adminChapterEdit/sagas";
import adminSlideEditSaga from "../adminSlideEdit/sagas";

import commentListSaga from "../commentList/sagas";
import adminCommentEditSaga from "../adminCommentEdit/sagas";

import emojiSaga from "../emoji/sagas";

import adminEmojiEditSaga from "../adminEmojiEdit/sagas";

import coinValueSaga from "../coinValue/sagas";
import adminCoinValueEditSaga from "../adminCoinValueEdit/sagas";




export function* rootSaga() {
  yield all([
      fork(serieChapterSaga),
      fork(userLoginSaga),
      fork(userEditSaga),
      fork(userInfoSaga),  
      fork(userInfoEditSaga),
      fork(slideSaga),
      
      fork(adminUserListSaga),
      fork(adminUserEditSaga),
      fork(adminUserNewSaga),
      fork(adminSerieEditSaga),
      fork(adminChapterEditSaga),
      fork(adminSlideEditSaga),

      fork(commentListSaga),
      fork(adminCommentEditSaga),

      fork(emojiSaga),
      fork(adminEmojiEditSaga),

      fork(coinValueSaga),
      fork(adminCoinValueEditSaga),

      
    ]);
}