import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchAdminUserEditFailure, fetchAdminUserEditSuccess } from "./actions";
import { FETCH_ADMIN_USER_EDIT_REQUEST } from "./actionTypes";

import { FetchAdminUserEditRequest } from "./types";

import { getAdminUserEditElim, getAdminUserEditUpdate } from "./api";

import { fetchAdminUserListSaga } from "../adminUserList/sagas";

export function* fetchAdminUserEditSaga(action: FetchAdminUserEditRequest): any {
  try {
    let response: any;
    let user: any;

    let rol = action.query.rol;

    if (rol.length == 0) {
      // delete
      response = yield call(getAdminUserEditElim, action.query.id);
    } else {
      // create or update
      response = yield call(getAdminUserEditUpdate, action.query.id, action.query.rol );
    }

    if (response && response.id &&  response.id!='') {
      yield call(fetchAdminUserListSaga);
      yield put(
        fetchAdminUserEditSuccess({
          result: response,
        })
      );
    }
    

    {/*
    if (response && response.uid && response.uid != "") {
      
      if (type == "insert" || type == "rs" ) {
        yield call(fetchUserLoginSaga, {
          query: { uid: response.uid, email: response.email },
        });
      }

      if (type == "update") {
        yield call(fetchUserLoginSaga, {
          query: { uid: response.uid, email: user.email },
        });
      }

      yield put(
        fetchAdminUserEditSuccess({
          result: response,
        })
      );
    }*/}
  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    if (error instanceof Error && error.toString().includes("auth/email-already-in-use")) {
      errorMessage = "Este correo electrónico está siendo usado en otra cuenta";
    }
    yield put(fetchAdminUserEditFailure({ error: errorMessage }));
  }
}

function* AdminUserEditSaga() {
  yield all([takeLatest(FETCH_ADMIN_USER_EDIT_REQUEST, fetchAdminUserEditSaga)]);
}

export default AdminUserEditSaga;
