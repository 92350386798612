import React, { Component, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'


import {
  getLoadingUserLoginSelector,
  getResultUserLoginSelector,
  getErrorUserLoginSelector,
} from "./_store/userLogin/selectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLoginRequest } from "./_store/userLogin/actions";

import { auth } from "./_store/firebase";


import {

  CSpinner,

} from '@coreui/react'


const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const Initial = React.lazy(() => import('./pages/initial/Initial'))
const Login = React.lazy(() => import('./pages/login/Login'))
const Logout = React.lazy(() => import('./pages/logout/Logout'))



const loading = (
  <div className="pt-3 text-center">
    {/*<div className="sk-spinner sk-spinner-pulse"></div>*/}
  </div>  
)






function App() {

  const user = useSelector(getResultUserLoginSelector);
  const dispatch = useDispatch();



/*
  useEffect(() => {
    if (!user) {
      //navigate("/login");
    }
    //dispatch(fetchSerieChapterRequest());
    //dispatch(fetchSlideRequest())
    
    auth.onAuthStateChanged((userRestore) => {
      userRestore &&
        dispatch(
          fetchUserLoginRequest({
            email: userRestore.email,
            uid: userRestore.uid,
            backend: true
          })
        );
    });
    
  }, []);
  */


  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
         
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<DefaultLayout />} />
            <Route path="*" element={<Navigate to="/login" replace />} />

        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
