import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchUserInfoFailure, fetchUserInfoSuccess } from "./actions";
import { FETCH_USER_INFO_REQUEST } from "./actionTypes";

import { FetchUserInfoRequest } from "./types";

import  { getUserInfo }  from "./api";

import {
  getResultUserLoginSelector,
} from "../userLogin/selectors";

  

/*
  Worker Saga: Fired on FETCH_USER_INFO_REQUEST action
*/
export function* fetchUserInfoSaga(action:any) : any { 
  try {

    let uid=''
    if (action.uid!='') {
      uid=action.uid
    } else {
      let user = yield select(getResultUserLoginSelector)
      uid = user.uid
    }
    

    


    const response = yield call(getUserInfo, uid);

    yield put(
      fetchUserInfoSuccess({
        result: response,
      })
    );
  



  } catch (e) {
    let errorMessage = "Failed to do something exceptional";
    if (e instanceof Error) {
      errorMessage = e.message;
    }
    yield put( fetchUserInfoFailure({ error: errorMessage }));

  }
}

/*
  Starts worker saga on latest dispatched `FETCH_USER_INFO_REQUEST` action.
  Allows concurrent increments.
*/
function* UserInfoSaga() {
  yield all([takeLatest(FETCH_USER_INFO_REQUEST, fetchUserInfoSaga)]);
}

export default UserInfoSaga;
