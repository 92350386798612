import db from "../_root/apiBridge";
import { timestamp } from "../_root/apiBridge";

export async function getAdminUserEditElim(uid: string): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      db.collection("users_rol")
        .doc(uid)
        .delete()
        .then(() => {
          resolve({
            id: uid,
          });
        });
    } catch (error) {
      reject(error);
    }
  });
}

export async function getAdminUserEditUpdate(
  uid: string,
  rol: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    db.collection("users_rol")
      .doc(uid)
      .get()
      .then((snapshot) => {
        let objField: any = {
          audit_updated: timestamp.fromDate(new Date()),
        };
        if (rol.includes("admin")) {
          objField = { ...objField, admin: true };
        } else {
          objField = { ...objField, admin: false };
        }
        if (rol.includes("editor")) {
          objField = { ...objField, editor: true };
        } else {
          objField = { ...objField, editor: false };
        }

        if (!snapshot.exists) {
          db.collection("users_rol")
            .doc(uid)
            .set(objField)
            .then(function (refDoc) {
              console.log("insert --->", uid);
              resolve({ id: uid });
            });
        } else {
          db.collection("users_rol")
            .doc(uid)
            .update(objField)
            .then(function () {
              resolve({ id: uid });
            });
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
