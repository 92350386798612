import {
    FETCH_USER_EDIT_REQUEST,
    FETCH_USER_EDIT_FAILURE,
    FETCH_USER_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchUserEditRequest,
    FetchUserEditSuccess,
    FetchUserEditSuccessPayload,
    FetchUserEditFailure,
    FetchUserEditFailurePayload,
  } from "./types";
  
  export const fetchUserEditRequest = (
    query: any
  ): FetchUserEditRequest => ({
    type: FETCH_USER_EDIT_REQUEST,
    query,
  });
  
  export const fetchUserEditSuccess = (
    payload: FetchUserEditSuccessPayload
  ): FetchUserEditSuccess => ({
    type: FETCH_USER_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchUserEditFailure = (
    payload: FetchUserEditFailurePayload
  ): FetchUserEditFailure => ({
    type: FETCH_USER_EDIT_FAILURE,
    payload,
  });