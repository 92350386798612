import {
    FETCH_ADMIN_USER_NEW_REQUEST,
    FETCH_ADMIN_USER_NEW_FAILURE,
    FETCH_ADMIN_USER_NEW_SUCCESS,
  } from "./actionTypes";
  import {
    FetchAdminUserNewRequest,
    FetchAdminUserNewSuccess,
    FetchAdminUserNewSuccessPayload,
    FetchAdminUserNewFailure,
    FetchAdminUserNewFailurePayload,
  } from "./types";
  
  export const fetchAdminUserNewRequest = (
    query: any
  ): FetchAdminUserNewRequest => ({
    type: FETCH_ADMIN_USER_NEW_REQUEST,
    query,
  });
  
  export const fetchAdminUserNewSuccess = (
    payload: FetchAdminUserNewSuccessPayload
  ): FetchAdminUserNewSuccess => ({
    type: FETCH_ADMIN_USER_NEW_SUCCESS,
    payload,
  });
  
  export const fetchAdminUserNewFailure = (
    payload: FetchAdminUserNewFailurePayload
  ): FetchAdminUserNewFailure => ({
    type: FETCH_ADMIN_USER_NEW_FAILURE,
    payload,
  });