import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchUserEditFailure, fetchUserEditSuccess } from "./actions";
import { FETCH_USER_EDIT_REQUEST } from "./actionTypes";

import { FetchUserEditRequest } from "./types";

import { getUserEditInsert, getUserEditUpdate, getUserEditRs } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchUserLoginSaga } from "../userLogin/sagas";

export function* fetchUserEditSaga(action: FetchUserEditRequest): any {
  try {
    let response: any;
    let user: any;

    let type = action.query.type;

    if (type == "insert") {
      response = yield call(getUserEditInsert, action.query);
    }
    if (type == "update") {
      user = yield select(getResultUserLoginSelector);
      response = yield call(getUserEditUpdate, user.uid, action.query);
    }
    if (type == "rs") {
      response = yield call(getUserEditRs, action.query);
    }

    if (response && response.uid && response.uid != "") {
      
      if (type == "insert" || type == "rs" ) {
        yield call(fetchUserLoginSaga, {
          query: { uid: response.uid, email: response.email },
        });
      }

      if (type == "update") {
        yield call(fetchUserLoginSaga, {
          query: { uid: response.uid, email: user.email },
        });
      }

      yield put(
        fetchUserEditSuccess({
          result: response,
        })
      );
    }
  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    if (error instanceof Error && error.toString().includes("auth/email-already-in-use")) {
      errorMessage = "Este correo electrónico está siendo usado en otra cuenta";
    }
    yield put(fetchUserEditFailure({ error: errorMessage }));
  }
}

function* UserEditSaga() {
  yield all([takeLatest(FETCH_USER_EDIT_REQUEST, fetchUserEditSaga)]);
}

export default UserEditSaga;
