import  db  from '../_root/apiBridge';



export async function getSlide(admin:boolean): Promise<any> {



  return new Promise((resolve, reject) => {


      db
        .collection('slide')
        //.where('published', '==', true)
        .orderBy('order', 'asc')
        .get()
        .then(doc => {


          if (doc.size == 0) {
            resolve([]);
          }

          let result: any = [];
          doc.forEach(docu => {         
              result.push({ ...docu.data(), id: docu.id });
          });

          if (result.length === doc.size) {
            if (admin) {
              resolve(result);  
            } else {
              resolve(result.filter(item => item.published === true))
            }
          }



        }).catch((error) => {
          console.log(error);
          reject(error);
        });




  








  });

}