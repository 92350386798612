import db from "../_root/apiBridge";
import { timestamp } from "../_root/apiBridge";

export async function getAdminEmojiEditInsert(query: any): Promise<any> {
  return new Promise((resolve, reject) => {

    delete query.id;

    try {
      db.collection("emoji")
        .add(query)
        .then(function (refDoc) {
          resolve({
            id: refDoc.id,
          });
        }).catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

export async function getAdminEmojiEditUpdate(
  id: string,
  query: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    db.collection("emoji")
      .doc(id)
      .update(query)
      .then(() => {
        resolve({
          id,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export async function getAdminEmojiEditElim(id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      db.collection("emoji")
        .doc(id)
        .delete()
        .then(() => {
          resolve({
            id,
          });
        }).catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}
