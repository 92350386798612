import {
    FETCH_USER_LOGIN_REQUEST,
    FETCH_USER_LOGIN_FAILURE,
    FETCH_USER_LOGIN_SUCCESS,
    FETCH_USER_LOGOUT,
  } from "./actionTypes";
  import {
    FetchUserLoginRequest,
    FetchUserLoginSuccess,
    FetchUserLoginSuccessPayload,
    FetchUserLoginFailure,
    FetchUserLoginFailurePayload,
    FetchUserLogout,
  } from "./types";
  
  export const fetchUserLoginRequest = (
    query: any
  ): FetchUserLoginRequest => ({
    type: FETCH_USER_LOGIN_REQUEST,
    query,
  });
  
  export const fetchUserLoginSuccess = (
    payload: FetchUserLoginSuccessPayload
  ): FetchUserLoginSuccess => ({
    type: FETCH_USER_LOGIN_SUCCESS,
    payload,
  });
  
  export const fetchUserLoginFailure = (
    payload: FetchUserLoginFailurePayload
  ): FetchUserLoginFailure => ({
    type: FETCH_USER_LOGIN_FAILURE,
    payload,
  });

  export const fetchUserLogout = (
  ): FetchUserLogout => ({
    type: FETCH_USER_LOGOUT,
  });