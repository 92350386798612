import {
  FETCH_SLIDE_REQUEST,
  FETCH_SLIDE_SUCCESS,
  FETCH_SLIDE_FAILURE,
  FETCH_SLIDE_INITIAL,
} from "./actionTypes";

import { SlideActions, SlideState } from "./types";

const initialState: SlideState = {
  loading: false,
  result: null,
  error: null,
};

export default (state = initialState, action: SlideActions) => {
  switch (action.type) {
    case FETCH_SLIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SLIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        error: null,
      };
    case FETCH_SLIDE_FAILURE:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload.error,
      };
    case FETCH_SLIDE_INITIAL:
      return {
        ...state,
        loading: false,
        result: null,
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};