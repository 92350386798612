import {
  FETCH_USER_INFO_REQUEST,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_INITIAL,
} from "./actionTypes";

import { UserInfoActions, UserInfoState } from "./types";

const initialState: UserInfoState = {
  loading: false,
  result: null,
  error: null,
};

export default (state = initialState, action: UserInfoActions) => {
  switch (action.type) {
    case FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        error: null,
      };
    case FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload.error,
      };
    case FETCH_USER_INFO_INITIAL:
      return {
        ...state,
        loading: false,
        result: null,
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};