import {
  FETCH_ADMIN_UI_SET,

} from "./actionTypes";

import { AdminUiActions, AdminUiState } from "./types";

const initialState: AdminUiState = {
  result: {sidebarShow: true},
};

export default (state = initialState, action: AdminUiActions) => {
  switch (action.type) {
    case FETCH_ADMIN_UI_SET:
      return {
        ...state,
        result: action.query,
      };
    default:
      return {
        ...state,
      };
  }
};