import { all, call, put, takeLatest } from "redux-saga/effects";

import { fetchSerieChapterFailure, fetchSerieChapterSuccess } from "./actions";
import { FETCH_SERIE_CHAPTER_REQUEST } from "./actionTypes";

import  { getSerieChapter }  from "./api";


  
  
  

/*
  Worker Saga: Fired on FETCH_SERIE_CHAPTER_REQUEST action
*/
export function* fetchSerieChapterSaga(action: any) : any { 
  try {
    const response = yield call(getSerieChapter, action.admin);
    yield put(
      fetchSerieChapterSuccess({
        result: response,
      })
    );
  } catch (e) {
    let errorMessage = "Failed to do something exceptional";
    if (e instanceof Error) {
      errorMessage = e.message;
    }
    yield put(
      fetchSerieChapterFailure({
        error: errorMessage,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_SERIE_CHAPTER_REQUEST` action.
  Allows concurrent increments.
*/
function* SerieChapterSaga() {
  yield all([takeLatest(FETCH_SERIE_CHAPTER_REQUEST, fetchSerieChapterSaga)]);
}

export default SerieChapterSaga;
