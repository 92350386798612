import {
    FETCH_ADMIN_USER_EDIT_REQUEST,
    FETCH_ADMIN_USER_EDIT_FAILURE,
    FETCH_ADMIN_USER_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchAdminUserEditRequest,
    FetchAdminUserEditSuccess,
    FetchAdminUserEditSuccessPayload,
    FetchAdminUserEditFailure,
    FetchAdminUserEditFailurePayload,
  } from "./types";
  
  export const fetchAdminUserEditRequest = (
    query: any
  ): FetchAdminUserEditRequest => ({
    type: FETCH_ADMIN_USER_EDIT_REQUEST,
    query,
  });
  
  export const fetchAdminUserEditSuccess = (
    payload: FetchAdminUserEditSuccessPayload
  ): FetchAdminUserEditSuccess => ({
    type: FETCH_ADMIN_USER_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchAdminUserEditFailure = (
    payload: FetchAdminUserEditFailurePayload
  ): FetchAdminUserEditFailure => ({
    type: FETCH_ADMIN_USER_EDIT_FAILURE,
    payload,
  });