/* APP */
//import firestore from '@react-native-firebase/firestore';
//export const timestamp =  firestore.Timestamp;
//const db = firestore();
//export default db;


/* WEB */
import db from '../firebase'
import { Timestamp } from "firebase/firestore";
export const timestamp =  Timestamp;
export default db;


/* COMUN */
export const disp = "Web"  // or App