import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchAdminSerieEditFailure, fetchAdminSerieEditSuccess } from "./actions";
import { FETCH_ADMIN_SERIE_EDIT_REQUEST } from "./actionTypes";

import { FetchAdminSerieEditRequest } from "./types";

import { getAdminSerieEditInsert, getAdminSerieEditUpdate, getAdminSerieEditElim } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchSerieChapterSaga } from "../serieChapter/sagas";

import generateLink from "../../_global/link"
import { urlSite } from "src/_global/constant";

export function* fetchAdminSerieEditSaga(action: FetchAdminSerieEditRequest): any {
  try {
    let response: any;
  
    if (action.query.idDelete && action.query.idDelete!='') {
      response = yield call(getAdminSerieEditElim, action.query.idDelete);
    } else {
      if (action.query.id && action.query.id!='') {
        response = yield call(getAdminSerieEditUpdate, action.query.id, action.query);
      } else {
        response = yield call(getAdminSerieEditInsert, {...action.query});  
      }
    }

   

    if (response && response.id && response.id != "") {
      yield call(fetchSerieChapterSaga, {admin: true});
      yield put(
        fetchAdminSerieEditSuccess({
          result: response,
        })
      );
    }


  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    yield put(fetchAdminSerieEditFailure({ error: errorMessage }));
  }
}

function* AdminSerieEditSaga() {
  yield all([takeLatest(FETCH_ADMIN_SERIE_EDIT_REQUEST, fetchAdminSerieEditSaga)]);
}

export default AdminSerieEditSaga;
