import {
    FETCH_COMMENT_LIST_REQUEST,
    FETCH_COMMENT_LIST_FAILURE,
    FETCH_COMMENT_LIST_SUCCESS,
  } from "./actionTypes";
  import {
    FetchCommentListRequest,
    FetchCommentListSuccess,
    FetchCommentListSuccessPayload,
    FetchCommentListFailure,
    FetchCommentListFailurePayload,
  } from "./types";
  
  export const fetchCommentListRequest = (
  ): FetchCommentListRequest => ({
    type: FETCH_COMMENT_LIST_REQUEST,
  });
  
  export const fetchCommentListSuccess = (
    payload: FetchCommentListSuccessPayload
  ): FetchCommentListSuccess => ({
    type: FETCH_COMMENT_LIST_SUCCESS,
    payload,
  });
  
  export const fetchCommentListFailure = (
    payload: FetchCommentListFailurePayload
  ): FetchCommentListFailure => ({
    type: FETCH_COMMENT_LIST_FAILURE,
    payload,
  });