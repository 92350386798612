import {
    FETCH_ADMIN_COMMENT_EDIT_REQUEST,
    FETCH_ADMIN_COMMENT_EDIT_FAILURE,
    FETCH_ADMIN_COMMENT_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchAdminCommentEditRequest,
    FetchAdminCommentEditSuccess,
    FetchAdminCommentEditSuccessPayload,
    FetchAdminCommentEditFailure,
    FetchAdminCommentEditFailurePayload,
  } from "./types";
  
  export const fetchAdminCommentEditRequest = (
    query: any
  ): FetchAdminCommentEditRequest => ({
    type: FETCH_ADMIN_COMMENT_EDIT_REQUEST,
    query,
  });
  
  export const fetchAdminCommentEditSuccess = (
    payload: FetchAdminCommentEditSuccessPayload
  ): FetchAdminCommentEditSuccess => ({
    type: FETCH_ADMIN_COMMENT_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchAdminCommentEditFailure = (
    payload: FetchAdminCommentEditFailurePayload
  ): FetchAdminCommentEditFailure => ({
    type: FETCH_ADMIN_COMMENT_EDIT_FAILURE,
    payload,
  });