import  db  from '../_root/apiBridge';



export async function getUserInfo(uid: string): Promise<any> {



  return new Promise((resolve, reject) => {

    if (
      uid !== ''
    ) {

      

      db
        .collection('user_info')
        .where('user', '==', uid)
        .orderBy('audit_updated', 'desc')
        .get()
        .then(doc => {


          if (doc.size == 0) {
            resolve([]);
          }

          let result: any = [];
          doc.forEach(docu => {            
            result.push({ ...docu.data(), id: docu.id });
          });

          if (result.length === doc.size) {
            resolve(result);
          }



        }).catch((error) => {
          reject(error);
        });




    } else {

      resolve([]);

    }








  });

}