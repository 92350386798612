import db from "../_root/apiBridge";
import { timestamp } from "../_root/apiBridge";



export async function getAdminSerieEditInsert(query: any): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      db.collection("series")
        .add({...query, audit_created: timestamp.fromDate(new Date())})
        .then(function (refDoc) {
          resolve({
            id: refDoc.id,
          });
        }).catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

export async function getAdminSerieEditUpdate(
  id: string,
  query: any
): Promise<any> {
  return new Promise((resolve, reject) => {


    //query = {...query, keyword: firestore.FieldValue.arrayRemove('asdasd', 'asdasdsd') }


    db.collection("series")
      .doc(id)
      .update(query)
      .then(() => {
        resolve({
          id,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export async function  getAdminSerieEditElim(id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      db.collection("series")
        .doc(id)
        .delete()
        .then(() => {
          resolve({
            id,
          });
        }).catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}
