import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchCommentListFailure, fetchCommentListSuccess } from "./actions";
import { FETCH_COMMENT_LIST_REQUEST } from "./actionTypes";

import { FetchCommentListRequest } from "./types";

import { getCommentListInsert } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchUserLoginSaga } from "../userLogin/sagas";

export function* fetchCommentListSaga(): any {
  try {
    let response: any;
    let user: any;

    user = yield select(getResultUserLoginSelector);


    response = yield call(getCommentListInsert, user.uid);

   

    if (response && response.data) {
  
      yield put(
        fetchCommentListSuccess({
          result: response.data,
        })
      );
    }
  } catch (error) {
    let errorMessage='';
    if ( error.toString().includes("email address is already in use")) {
      errorMessage = "Este correo electrónico está siendo usado en otra cuenta";
    } else {
      errorMessage = error;
    }
    yield put(fetchCommentListFailure({ error: errorMessage }));
  }
}

function* CommentListSaga() {
  yield all([takeLatest(FETCH_COMMENT_LIST_REQUEST, fetchCommentListSaga)]);
}

export default CommentListSaga;
