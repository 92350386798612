import db  from '../_root/apiBridge';

export async function getSerieChapter(admin:boolean): Promise<any> {

    return new Promise((resolve, reject) => {

      
      db
        .collection('series')
        //.where('promote', '==', true)
        //.orderBy('audit_created', 'desc') //  by order
        //.limit(1)
        .get()
        .then(snapshot => {
  
          if (snapshot.size === 0) {
            resolve([]);
          }
  
          let result: any = [];
  
          snapshot.forEach((doc: any) => {
  
  
            db.collection('chapters')
              .where('serie_id', '==', doc.id)
              //.where('premiere', '==', false)
              //.orderBy('free', 'asc')
              .orderBy('chapter', 'asc')
              .get()
              .then(snapshot2 => {
  
                let result2: any = [];
                snapshot2.forEach(doc2 => {
                  result2.push({ ...doc2.data(), id: doc2.id, 
                    serieId:  doc.id, 
                    serieTitle: doc.title,
                    serieLogo: doc.img_logo,
                  })
                });

               /* if (snapshot2.size === 0) {
                  result.push({ ...doc.data(), id: doc.id, chapter: []  });
                } */

                if (result2.length === snapshot2.size) { 
                  result2.sort((a, b) => a.chapter - b.chapter); // order by chapter number
                  let chapterFree: any = [];
                  let chapterExclusive: any = [];
                  result2.map((item) => {
                    if (item.type && item.type == "chapter") {
                      chapterFree.push(item);
                    } else {
                      chapterExclusive.push(item);
                    }                  
                  });
                  result.push({ ...doc.data(), id: doc.id, chapter: result2, chapterFree, chapterExclusive  });
                }




                if (result.length === snapshot.size) {
                  result.sort((a, b) => a.order - b.order); // order by serie order number

                  if (admin) {
                    resolve(result);  
                  } else {
                    resolve(result.filter(item => item.promote === true))

                  }

                  
                }
  
              }); 
  
          });
  
        }).catch((error) => {
          console.log( error);
  
          reject(error);
        });
  
    });
  
  }

export async function getSerieChapter2() {

    return db.collection('series')
        //.where('serie_id', '==', doc.id)
        //.where('premiere', '==', false)
        //.orderBy('chapter', 'asc')
        .get()
        .then(snapshot => {

            let result: any = [];
            snapshot.forEach(doc => {
                result.push({ ...doc.data(), id: doc.id })
            });

            if (result.length === snapshot.size) {

                return result;

            }

        });

}