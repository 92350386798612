import { combineReducers } from "redux";

import serieChapterReducer from "../serieChapter/reducer";
import userLoginReducer from "../userLogin/reducer";
import userEditReducer from "../userEdit/reducer";
import userInfoReducer from "../userInfo/reducer";
import  userInfoEditReducer  from "../userInfoEdit/reducer";
import  slideReducer  from "../slide/reducer";

// admin
import  adminUserListReducer  from "../adminUserList/reducer";
import  adminUserEditReducer  from "../adminUserEdit/reducer";
import  adminUserNewReducer  from "../adminUserNew/reducer";
import  adminSerieEditReducer  from "../adminSerieEdit/reducer";
import  adminChapterEditReducer  from "../adminChapterEdit/reducer";
import  adminUiReducer  from "../adminUi/reducer";
import  adminSlideEditReducer  from "../adminSlideEdit/reducer";

import  commentListReducer  from "../commentList/reducer";
import  adminCommentEditReducer  from "../adminCommentEdit/reducer";


import  emojiReducer  from "../emoji/reducer";

import  adminEmojiEditReducer  from "../adminEmojiEdit/reducer";

import  coinValueReducer  from "../coinValue/reducer";

import  adminCoinValueEditReducer  from "../adminCoinValueEdit/reducer";










const rootReducer = combineReducers({
  serieChapter: serieChapterReducer,
  userLogin: userLoginReducer,
  userEdit: userEditReducer,
  userInfo: userInfoReducer,
  userInfoEdit: userInfoEditReducer,
  slide: slideReducer,

  adminUserList : adminUserListReducer,
  adminUserEdit: adminUserEditReducer,
  adminUserNew: adminUserNewReducer,
  adminSerieEdit: adminSerieEditReducer,
  adminChapterEdit: adminChapterEditReducer,
  adminUi: adminUiReducer,
  adminSlideEdit:adminSlideEditReducer,

  commentList:commentListReducer,
  adminCommentEdit:adminCommentEditReducer,

  emoji: emojiReducer,
  adminEmojiEdit : adminEmojiEditReducer,

  coinValue: coinValueReducer,
  adminCoinValueEdit : adminCoinValueEditReducer,



  
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;