import {
    FETCH_SLIDE_REQUEST,
    FETCH_SLIDE_FAILURE,
    FETCH_SLIDE_SUCCESS,
    FETCH_SLIDE_INITIAL,
  } from "./actionTypes";
  import {
    FetchSlideRequest,
    FetchSlideSuccess,
    FetchSlideSuccessPayload,
    FetchSlideFailure,
    FetchSlideFailurePayload,
    FetchSlideInitial,
  } from "./types";
  
  export const fetchSlideRequest = (admin:boolean=false): FetchSlideRequest => ({
    type: FETCH_SLIDE_REQUEST,
    admin
  });
  
  export const fetchSlideSuccess = (
    payload: FetchSlideSuccessPayload
  ): FetchSlideSuccess => ({
    type: FETCH_SLIDE_SUCCESS,
    payload,
  });
  
  export const fetchSlideFailure = (
    payload: FetchSlideFailurePayload
  ): FetchSlideFailure => ({
    type: FETCH_SLIDE_FAILURE,
    payload,
  });

  export const fetchSlideInitial = (): FetchSlideInitial => ({
    type: FETCH_SLIDE_INITIAL,
  });