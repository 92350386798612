import db from '../_root/apiBridge';
import { timestamp } from '../_root/apiBridge';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
//import auth from '@react-native-firebase/auth';

import { auth } from "../firebase";




export async function getAdminUserNewInsert(uid: string, query:any): Promise<any> {


  const {
    email,
    name,
    phone,
    password,
  } = query;

  return new Promise((resolve, reject) => {
   

    try {


      //process.env.REACT_APP_FUNCTIONS_URL,


      auth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then(function (idToken) {             
            // Default options are marked with *
            console.log('','idToken '+idToken+' uid '+uid);
            fetch(process.env.REACT_APP_FUNCTIONS_URL+"/adminCreateUser", {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              //mode: 'no-cors', // no-cors, *cors, same-origin
              //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              //credentials: 'same-origin', // include, *same-origin, omit
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              //redirect: 'follow', // manual, *follow, error
              //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
              body: JSON.stringify({                
                  uid,
                  email,
                  password                
              }) // body data type must match "Content-Type" header
            })
            .then((response) => response.json())
            .then(
              (response) => {
                console.log('RESP:', response)
                if (response.data && response.data.uid) {
                  db.collection('users').doc(response.data.uid)
                  .set({
                    email: email,
                    name: name,
                    phone: phone??'',
                    audit_created: timestamp.fromDate(new Date()),
                    deviceToken: '',
                  }).then(()=>{
                    resolve({
                      uid: response.data.uid,
                      email: email,        
                    });
                  });

                } else {
                  reject(response.data.error??'Failed to do something exceptional')
                }                
              }
            ).catch(error =>  reject(error));
  
          });
        }
      });

      /*
      createUserWithEmailAndPassword(getAuth(), query.email, query.password)
      //auth().createUserWithEmailAndPassword(query.email, query.password) // create
        .then((currentUser) => {

          db.collection('users').doc(currentUser.user.uid)
            .set({
              email: email,
              name: name,
              phone: phone??'',
              audit_created: timestamp.fromDate(new Date()),
              deviceToken: '',
            }).then(()=>{
              resolve({
                uid: currentUser.user.uid,
                email: email,        
              });
            });


        }).catch(error => {
          
          reject(error);

        });
*/




    } catch (error) {
      reject(error);
    }


  });

}

